.text-center {
    text-align: center;
}

.timer {
    margin: auto;
}

h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 400;
}

.icon {
    position: fixed;
    top: 10px;
    left: 10px;
}

.digits {
    position: relative;
    font-size: 60px;
}

.symbol {
    margin: 0 10px;
    font-size: 40px;
}

.value {
    margin-bottom: 14px;
    padding-bottom: 16px;
}

.legend {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

.emoji {
    font-size: 20px;
}

@media(max-width: 399px) {
    .symbol {
        display: none;
    }

    .value {
        text-align: center;
    }
}

@media(min-width: 400px) {
    .digits {
        display: inline-block;
    }

    .value {
        padding-bottom: 10px;
    }
}

@media(min-width: 600px) {
    .digits {
        font-size: 80px;
    }

    .value {
        padding-bottom: 6px;
    }

    .legend {
        font-size: 14px;
    }

    .symbol {
        font-size: 60px;
    }
}

@media(min-width: 900px) {
    .digits {
        font-size: 120px;
    }

    .value {
        padding-bottom: 0px;
    }

    .legend {
        font-size: 18px;
    }

    .symbol {
        font-size: 100px;
    }
}

@media(min-width: 1200px) {
    .digits {
        font-size: 180px;
    }

    .legend {
        font-size: 24px;
    }

    .symbol {
        font-size: 140px;
    }
}